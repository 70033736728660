<template>
  <div class="broker-form">
    <Header class="" />
    <h2 class="margin-top25" />
    <b-overlay :show="show" rounded="sm">
      <form-wizard
        color="#366ce3"
        :title="$t('BrokerForm.Register_Broker')"
        :subtitle="$t('BrokerForm.Complete_your_account')"
        shape="square"
        :finish-button-text="$t('common.Submit')"
        :next-button-text="$t('common.Next')"
        :back-button-text="$t('common.Previous')"
        class="mb-3 pt-1"
        @on-complete="formSubmitted"
      >
        <!-- Broker details tab -->
        <tab-content
          :title="$t('BrokerForm.Broker_Details')"
          icon="feather icon-file-text"
          :before-change="validationForm"
        >
          <validation-observer ref="accountRules" tag="form">
            <b-row>
              <b-col cols="12" class="mb-2">
                <h5 class="mb-0">{{ $t("BrokerForm.Broker_Details") }}</h5>
                <small class="text-muted">
                  {{ $t("BrokerForm.Enter_Broker_Details") }}
                </small>
              </b-col>
              <b-col md="6">
                <b-form-group
                  :label="$t('BrokerForm.Full_Name')"
                  label-for="Fomraml_name"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="username"
                    rules="required"
                  >
                    <b-form-input
                      id="Fomraml_name"
                      v-model="createBrokerProfile.name"
                      :state="errors.length > 0 ? false : null"
                      :placeholder="$t('BrokerForm.Full_Name')"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col md="6">
                <b-form-group :label="$t('BrokerForm.Email')" label-for="email">
                  <validation-provider
                    #default="{ errors }"
                    name="email"
                    rules="required|email"
                  >
                    <b-form-input
                      id="email"
                      v-model="createBrokerProfile.emailValue"
                      type="email"
                      :state="errors.length > 0 ? false : null"
                      placeholder="john.doe@email.com"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col md="6">
                <b-form-group
                  :label="$t('BrokerForm.Phone_Number')"
                  label-for="Phone"
                >
                  <validation-provider name="phone" rules="required">
                    <VuePhoneNumberInput
                      v-model="phoneNumber"
                      color="#ea5455"
                      error-color="#ea5455"
                      @update="onUpdate"
                      :translations="
                        $store.state.locale.locale == 'ar'
                          ? translationsArabic
                          : translations
                      "
                    />

                    <!-- <small class="text-danger">{{ errors[0] }}</small> -->
                    <small v-if="!isValidNumber" class="text-danger">{{
                      $t("common.phone_Invalid")
                    }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>

              <b-col md="6">
                <validation-provider
                  #default="{ errors }"
                  name="owner_type"
                  rules="required"
                >
                  <b-form-group :state="errors.length > 0 ? false : null">
                    <label class="required" label-for="owner_type"
                      >{{ $t("BrokerForm.Owner_Type") }}
                    </label>

                    <v-select
                      id="owner_type"
                      v-model="createBrokerProfile.ownerType"
                      :options="
                        $store.state.locale.locale == 'ar'
                          ? OwnerTypeOptionArabic
                          : OwnerTypeOption
                      "
                      :selectable="
                        (option) => !option.value.includes('nothing_selected')
                      "
                      label="text"
                    />
                    <b-form-invalid-feedback
                      :state="errors.length > 0 ? false : null"
                    >
                      {{ errors[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </b-col>
              <b-col
                md="6"
                v-if="createBrokerProfile.ownerType.value == 'individual'"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Gender"
                  rules="required"
                >
                  <b-form-group
                    :label="$t('BrokerForm.Gender')"
                    label-for="Gender"
                    :state="errors.length > 0 ? false : null"
                  >
                    <v-select
                      id="Gender"
                      v-model="createBrokerProfile.selectedGender"
                      :options="
                        $store.state.locale.locale == 'ar'
                          ? createBrokerProfile.GenderOptionArabic
                          : createBrokerProfile.GenderOption
                      "
                      :selectable="
                        (option) => !option.value.includes('nothing_selected')
                      "
                      label="text"
                    />
                    <b-form-invalid-feedback
                      :state="errors.length > 0 ? false : null"
                    >
                      {{ errors[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </b-col>
            </b-row>
          </validation-observer>
        </tab-content>

        <!-- Broker Information tab -->
        <tab-content
          :title="$t('BrokerForm.Broker_Info')"
          icon="feather icon-user"
          :before-change="validationFormInfo"
        >
          <validation-observer ref="infoRules" tag="form">
            <b-row>
              <b-col cols="12" class="mb-2">
                <h5 class="mb-0">{{ $t("BrokerForm.Broker_Info") }}</h5>
                <small class="text-muted">{{
                  $t("BrokerForm.Enter_Broker_Info")
                }}</small>
              </b-col>
              <b-col md="6">
                <b-form-group
                  :label="$t('BrokerForm.License_details')"
                  label-for="licenseDetails"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="licenseDetails"
                    rules="required"
                  >
                    <b-form-input
                      id="licenseDetails"
                      v-model="createBrokerProfile.licenseDetails"
                      :state="errors.length > 0 ? false : null"
                      :placeholder="$t('BrokerForm.License_details')"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col md="6">
                <validation-provider
                  #default="{ errors }"
                  name="Nationality"
                  rules="required"
                >
                  <b-form-group
                    :label="$t('BrokerForm.Your_nationality')"
                    label-for="Nationality"
                    :state="errors.length > 0 ? false : null"
                  >
                    <v-select
                      id="Nationality"
                      v-model="createBrokerProfile.selectedNationality"
                      :options="countries"
                      :selectable="
                        (option) => !option.value.includes('nothing_selected')
                      "
                      label="text"
                    />
                    <b-form-invalid-feedback
                      :state="errors.length > 0 ? false : null"
                    >
                      {{ errors[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </b-col>
              <b-col md="6">
                <validation-provider
                  #default="{ errors }"
                  name="Industry"
                  rules="required"
                >
                  <b-form-group
                    :label="$t('BrokerForm.industries_company_belong')"
                    label-for="Industry"
                    :state="errors.length > 0 ? false : null"
                  >
                    <div id="app">
                      <treeselect
                        v-model="createBrokerProfile.selectedIndustry"
                        :multiple="true"
                        :placeholder="$t('common.Select')"
                        :options="industries"
                        @input="handeTreeSelect"
                        :normalizer="
                          $store.state.locale.locale == 'ar'
                            ? normalizerArabic
                            : normalizer
                        "
                      />
                    </div>
                    <b-form-invalid-feedback
                      :state="errors.length > 0 ? false : null"
                    >
                      {{ errors[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </b-col>
              <b-col md="6">
                <validation-provider
                  #default="{ errors }"
                  name="Country"
                  rules="required"
                >
                  <b-form-group
                    :label="$t('BrokerForm.Your_country')"
                    label-for="Country"
                    :state="errors.length > 0 ? false : null"
                  >
                    <v-select
                      id="Country"
                      v-model="createBrokerProfile.selectedCountry"
                      multiple
                      :options="countries"
                      :selectable="
                        (option) => !option.value.includes('nothing_selected')
                      "
                      label="text"
                    />
                    <b-form-invalid-feedback
                      :state="errors.length > 0 ? false : null"
                    >
                      {{ errors[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </b-col>
              <b-col md="6">
                <b-form-group
                  :label="$t('BrokerForm.Brief_overview_yourself')"
                  label-for="Highlights"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="Highlights"
                    :rules="`required|max:${$maxHighlightsUser}`"
                  >
                    <b-form-textarea
                      id="Highlights"
                      v-model="createBrokerProfile.Highlights"
                      :state="errors.length > 0 ? false : null"
                      :placeholder="$t('BrokerForm.Brief_overview_yourself')"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col md="6">
                <b-form-group
                  :label="$t('BrokerForm.description_yourself')"
                  label-for="description"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="description"
                    rules="required"
                  >
                    <b-form-textarea
                      id="description"
                      v-model="createBrokerProfile.description"
                      :state="errors.length > 0 ? false : null"
                      :placeholder="$t('BrokerForm.description_yourself')"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col md="6">
                <b-form-group
                  :label="$t('BrokerForm.description_previous_work')"
                  label-for="previousWork"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="previousWork"
                    rules="required"
                  >
                    <b-form-textarea
                      id="previousWork"
                      v-model="createBrokerProfile.previousWork"
                      :state="errors.length > 0 ? false : null"
                      :placeholder="$t('BrokerForm.description_previous_work')"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
            </b-row>
          </validation-observer>
        </tab-content>

        <!-- Experiences  tab-->
        <tab-content
          :title="$t('BrokerForm.Broker_Experiences')"
          icon="feather icon-tag"
          :before-change="validationFormAddress"
        >
          <validation-observer ref="addressRules" tag="form">
            <b-row>
              <b-col cols="12" class="mb-2">
                <h5 class="mb-0">{{ $t("BrokerForm.About_you") }}</h5>
                <small class="text-muted">{{
                  $t("BrokerForm.Enter_Language_Experiences")
                }}</small>
              </b-col>
              <b-col md="12">
                <b-form-group label="" label-for="language">
                  <validation-provider
                    #default="{ errors }"
                    name="language"
                    rules=""
                  >
                    <form-repeater-basic :items="items" />
                    <!-- <b-form-textarea
                    id="description"
                    v-model="createCompanyProfile.description"
                    :state="errors.length > 0 ? false : null"
                    placeholder="Descripe your company"
                  /> -->
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col md="12">
                <b-form-group label="" label-for="Experience">
                  <validation-provider
                    #default="{ errors }"
                    name="Experience"
                    rules=""
                  >
                    <Experience :experiences="experiences" />
                    <!-- <b-form-textarea
                    id="description"
                    v-model="createCompanyProfile.description"
                    :state="errors.length > 0 ? false : null"
                    placeholder="Descripe your company"
                  /> -->
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>

              <!-- <b-col md="6">
              <b-form-group
                label="City"
                label-for="City"
              >
                <validation-provider
                  #default="{ errors }"
                  name="City"
                  rules=""
                >
                  <b-form-input
                    id="City"
                    v-model="createBrokerProfile.city"
                    :state="errors.length > 0 ? false : null"
                    placeholder="city .."
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group
                label="otherInfo"
                label-for="otherInfo"
              >
                <validation-provider
                  #default="{ errors }"
                  name="otherInfo"
                  rules=""
                >
                  <b-form-input
                    id="otherInfo"
                    v-model="createBrokerProfile.otherInfo"
                    :state="errors.length > 0 ? false : null"
                    placeholder="other info .."
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col> -->
            </b-row>
          </validation-observer>
        </tab-content>
        <!-- social link -->
        <tab-content
          :title="$t('BrokerForm.Social_Links')"
          icon="feather icon-link"
          :before-change="validationFormSocial"
        >
          <validation-observer ref="socialRules" tag="form">
            <b-row>
              <b-col cols="12" class="mb-2">
                <h5 class="mb-0">{{ $t("BrokerForm.Social_Links") }}</h5>
                <small class="text-muted">{{
                  $t("BrokerForm.Enter_Social_Links")
                }}</small>
              </b-col>
              <b-col md="6">
                <b-form-group
                  :label="$t('BrokerForm.Facebook')"
                  label-for="facebook"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="Facebook"
                    rules="url"
                  >
                    <b-form-input
                      id="facebook"
                      v-model="createBrokerProfile.facebookUrl"
                      :state="errors.length > 0 ? false : null"
                      placeholder="https://facebook.com/abc"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col md="6">
                <b-form-group
                  :label="$t('BrokerForm.Twitter')"
                  label-for="twitter"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="Twitter"
                    rules="url"
                  >
                    <b-form-input
                      id="twitter"
                      v-model="createBrokerProfile.twitterUrl"
                      :state="errors.length > 0 ? false : null"
                      placeholder="https://twitter.com/abc"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col md="6">
                <b-form-group
                  :label="$t('BrokerForm.LinkedIn')"
                  label-for="linked-in"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="LinkedIn"
                    rules="url"
                  >
                    <b-form-input
                      id="linked-in"
                      v-model="createBrokerProfile.linkedinUrl"
                      :state="errors.length > 0 ? false : null"
                      placeholder="https://linkedin.com/abc"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col md="6">
                <b-form-group
                  :label="$t('BrokerForm.Website')"
                  label-for="Other"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="Other"
                    rules="url"
                  >
                    <b-form-input
                      id="Other"
                      v-model="createBrokerProfile.OtherUrl"
                      :state="errors.length > 0 ? false : null"
                      placeholder="https://other.com/abc"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
            </b-row>
          </validation-observer>
        </tab-content>
        <!-- files -->
        <tab-content
          :title="$t('BrokerForm.Attachment')"
          icon="feather icon-file-plus"
          :before-change="validationFormFiles"
        >
          <validation-observer ref="attachmentRules" tag="form">
            <b-row>
              <b-col cols="12" class="mb-2">
                <h5 class="mb-0">{{ $t("BrokerForm.Attachment") }}</h5>
                <small class="text-muted"
                  >{{ $t("BrokerForm.Upload_your_files") }}
                </small>
              </b-col>
              <!-- <b-col md="8">
                <b-form-group
                  label="Add Personal Photo"
                  label-for="PersonalPhoto"
                >
                  <vue-dropzone
                    id="PersonalPhoto"
                    ref="myVueDropzone"
                    v-model="createBrokerProfile.PersonalPhoto"
                    :options="dropzoneOptionsPersonalImage"
                    @click="removeThisFile(UploadFile)"
                  />
                </b-form-group>
              </b-col> -->
              <!-- <b-col md="8">
                <b-form-group
                  label="Broker Prove (Passport or national ID)"
                  label-for="Broker-Prove"
                >
                  <vue-dropzone
                    id="Broker-Prove"
                    ref="myVueDropzone"
                    v-model="createBrokerProfile.BrokerProve"
                    :options="dropzoneOptionsProof"
                    @click="removeThisFile(UploadFile)"
                  />
                </b-form-group>
              </b-col> -->
              <!-- <b-col md="8">
                <b-form-group
                  label="Add your certificates"
                  label-for="Broker-certificates"
                >
                  <vue-dropzone
                    id="Broker-certificates"
                    ref="myVueDropzone"
                    v-model="createBrokerProfile.BrokerCertificates"
                    :options="dropzoneOptionsCertificates"
                    @click="removeThisFile(UploadFile)"
                  />
                </b-form-group>
              </b-col> -->
              <!-- <b-col md="8">
                <b-form-group
                  label="Attach experenice, docs and relevant Docouments"
                  label-for="relevant-Docouments"
                >
                  <vue-dropzone
                    id="relevant-Docouments"
                    ref="myVueDropzone"
                    v-model="createBrokerProfile.BrokerRelevant"
                    :options="dropzoneOptionRelevant"
                    @click="removeThisFile(UploadFile)"
                  />
                </b-form-group>
              </b-col> -->

              <b-col md="6">
                <b-form-group>
                  <validation-provider
                    #default="{ errors }"
                    name="Personal Photo"
                    :rules="`required|size:${$sizeFilesUser}|mimes:image/*`"
                  >
                    <label for="PersonalPhoto"
                      >{{ $t("BrokerForm.Upload_Personal_Photo") }}
                    </label>
                    <b-form-file
                      id="PersonalPhoto"
                      v-model="createBrokerProfile.PersonalPhoto"
                      required
                      accept="image/jpeg, image/png"
                      :state="errors.length > 0 ? false : null"
                      :placeholder="$t('common.Choose_file_drop')"
                      drop-placeholder="Drop file here..."
                      :multiple="false"
                      :browse-text="$t('common.Browse')"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col md="6">
                <b-form-group>
                  <validation-provider
                    #default="{ errors }"
                    name="Broker Prove"
                    :rules="`required|size:${$sizeFilesUser}|mimes:image/*`"
                  >
                    <label for="Broker-Prove"
                      >{{ $t("BrokerForm.Broker_Prove") }}
                    </label>
                    <b-form-file
                      id="Broker-Prove"
                      v-model="createBrokerProfile.BrokerProve"
                      required
                      accept="image/jpeg, image/png, image/gif"
                      :state="errors.length > 0 ? false : null"
                      :placeholder="$t('common.Choose_file_drop')"
                      drop-placeholder="Drop file here..."
                      :multiple="false"
                      :browse-text="$t('common.Browse')"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col md="6">
                <b-form-group>
                  <validation-provider
                    #default="{ errors }"
                    name="certificates"
                    :rules="`size:${$sizeFilesUser}|`"
                  >
                    <label for="Broker-certificates"
                      >{{ $t("BrokerForm.Upload_certificates") }}
                    </label>
                    <b-form-file
                      id="Broker-certificates"
                      v-model="createBrokerProfile.BrokerCertificates"
                      accept="application/pdf, application/vnd.ms-excel,.doc,.docx,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                      :state="errors.length > 0 ? false : null"
                      :placeholder="$t('common.Choose_file_drop')"
                      drop-placeholder="Drop file here..."
                      multiple
                      :browse-text="$t('common.Browse')"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col md="6">
                <b-form-group>
                  <validation-provider
                    #default="{ errors }"
                    name="relevant docouments"
                    :rules="`size:${$sizeFilesUser}`"
                  >
                    <label for="relevant-Docouments"
                      >{{ $t("BrokerForm.relevant_Docouments") }}
                    </label>
                    <b-form-file
                      id="relevant-Docouments"
                      v-model="createBrokerProfile.BrokerRelevant"
                      accept="application/pdf, application/vnd.ms-excel,.doc,.docx,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                      :state="errors.length > 0 ? false : null"
                      :placeholder="$t('common.Choose_file_drop')"
                      drop-placeholder="Drop file here..."
                      multiple
                      :browse-text="$t('common.Browse')"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>

              <b-col md="12">
                <b-alert
                  v-if="errorMessage"
                  show
                  variant="warning"
                  class="warning p-1"
                  style="color: red"
                  >{{ errorMessage }}</b-alert
                >
              </b-col>
            </b-row>
          </validation-observer>
        </tab-content>
      </form-wizard>
    </b-overlay>
    <Footer class="company-form-footer" />
  </div>
</template>

<script>
import store from "@/store";
import Header from "@/layouts/landpage/Header.vue";
import Footer from "@/layouts/landpage/Footer.vue";
import { FormWizard, TabContent } from "vue-form-wizard";
import vSelect from "vue-select";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import "vue-form-wizard/dist/vue-form-wizard.min.css";
import vue2Dropzone from "vue2-dropzone";
import "vue2-dropzone/dist/vue2Dropzone.min.css";
import {
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BAlert,
  BFormInvalidFeedback,
  BFormDatepicker,
  BFormTextarea,
  BFormFile,
  BOverlay,
} from "bootstrap-vue";
import { required, email } from "@validations";
import { mapActions, mapGetters } from "vuex";
import VuePhoneNumberInput from "vue-phone-number-input";
import Experience from "../form-repeater/ExperiencRepeater.vue";
import FormRepeaterBasic from "../form-repeater/LanguageRepeater.vue";
import "vue-phone-number-input/dist/vue-phone-number-input.css";
// eslint-disable-next-line import/order
import Treeselect from "@riophae/vue-treeselect";
import "@riophae/vue-treeselect/dist/vue-treeselect.css";

import "../../../layouts/landpage/ValidationFrom.js";

export default {
  components: {
    Header,
    Footer,
    ValidationProvider,
    ValidationObserver,
    FormWizard,
    TabContent,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    vSelect,
    BAlert,
    BFormInvalidFeedback,
    // eslint-disable-next-line vue/no-unused-components
    ToastificationContent,
    // eslint-disable-next-line vue/no-unused-components
    BFormDatepicker,
    BFormTextarea,
    vueDropzone: vue2Dropzone,
    FormRepeaterBasic,
    Experience,
    VuePhoneNumberInput,
    Treeselect,
    BFormFile,
    BOverlay,
  },
  data() {
    return {
      translations: {
        countrySelectorLabel: "Country code",
        countrySelectorError: "Choose a country",
        phoneNumberLabel: "Phone number",
        example: "Exemple :",
      },
      translationsArabic: {
        countrySelectorLabel: "رمز البلد",
        countrySelectorError: "اختر دولة",
        phoneNumberLabel: "رقم الهاتف",
        example: "مثال :",
      },
      show: false,
      errorMessage: "",
      isValidNumber: null,
      phoneNumber: null,
      phoneCode: "",
      experiences: [],
      items: [],
      certificates: [],
      institute: [],
      createBrokerProfile: {
        selectedContry: "",
        name: "",
        emailValue: "",
        ownerType: "",
        licenseDetails: "",
        SelectedphoneCode: "",
        Phone: "",
        previousWork: "",
        selectedGender: "",
        selectedNationality: "",
        selectedIndustry: [],
        selectedSkills: [],
        Highlights: "",
        description: "",
        selectedCity: "",
        selectedState: "",
        selectedCountry: [],
        otherInfo: "",
        twitterUrl: "",
        facebookUrl: "",
        OtherUrl: "",
        linkedinUrl: "",

        PersonalPhoto: null,
        BrokerProve: null,
        BrokerCertificates: [],
        BrokerRelevant: [],
        GenderOption: [
          { value: "male", text: "Male" },
          { value: "female", text: "Female" },
        ],
        GenderOptionArabic: [
          { value: "male", text: "ذكر" },
          { value: "female", text: "أنثى" },
        ],
      },
      OwnerTypeOption: [
        { value: "individual", text: "Individual" },
        { value: "group", text: "Group" },
      ],
      OwnerTypeOptionArabic: [
        { value: "individual", text: "فرد" },
        { value: "group", text: "مجموعة" },
      ],
      // dropzoneOptionsPersonalImage: {
      //   addRemoveLinks: true,
      //   url: "https://httpbin.org/post",
      //   thumbnailWidth: 150,
      //   acceptedFiles: "image/*",
      //   maxFilesize: 3,
      //   maxFiles: 1,
      //   headers: { "My-Awesome-Header": "header value" },
      // },
      // dropzoneOptionsProof: {
      //   addRemoveLinks: true,
      //   url: "https://httpbin.org/post",
      //   thumbnailWidth: 150,
      //   acceptedFiles: ".xlsx,.xls,.doc, .docx,.ppt, .pptx,.txt,.pdf",
      //   maxFilesize: 3,
      //   headers: { "My-Awesome-Header": "header value" },
      // },
      // dropzoneOptionsCertificates: {
      //   addRemoveLinks: true,
      //   url: "https://httpbin.org/post",
      //   thumbnailWidth: 150,
      //   acceptedFiles: ".xlsx,.xls,.doc, .docx,.ppt, .pptx,.txt,.pdf",
      //   maxFilesize: 3,
      //   headers: { "My-Awesome-Header": "header value" },
      // },
      // dropzoneOptionRelevant: {
      //   addRemoveLinks: true,
      //   url: "https://httpbin.org/post",
      //   thumbnailWidth: 150,
      //   acceptedFiles: "image/*,.xlsx,.xls,.doc, .docx,.ppt, .pptx,.txt,.pdf",
      //   maxFilesize: 3,
      //   headers: { "My-Awesome-Header": "header value" },
      // },
    };
  },
  computed: {
    ...mapGetters({
      categories: "profile/getInvestorCategories",
      industries: "profile/getAllIndustries",
      currencies: "currency/getCurrencies",
      countries: "country/getCountries",
      user: "auth/user",
      skills: "profile/getAllSkills",
    }),
  },
  created() {
    store.commit("appConfig/UPDATE_NAV_MENU_HIDDEN", true);
    store.commit("appConfig/UPDATE_FOOTER_CONFIG", { type: "hidden" });
    store.commit("appConfig/UPDATE_NAVBAR_CONFIG", { type: "hidden" });
  },

  methods: {
    ...mapActions({
      fetchCategories: "profile/retreiveCategories",
      fetchIndustries: "profile/retreiveIndustries",
      fetchCurrencies: "currency/retreiveCurrencies",
      createBroker: "profile/createBroker",
      fetchCountries: "country/retreiveCountries",
    }),
    onUpdate(payload) {
      if (this.phoneNumber == null) {
        this.isValidNumber = true;
      } else {
        this.isValidNumber = payload.isValid;
      }

      this.phoneCode = payload.countryCode;
    },
    normalizerArabic(node) {
      return {
        id: node.id,
        label: node.arabicName != null ? node.arabicName : node.label,
        children: node.children,
      };
    },
    normalizer(node) {
      return {
        id: node.id,
        label: node.label,
        children: node.children,
      };
    },
    handeTreeSelect(data) {
      if (data.length > 6) {
        this.createBrokerProfile.selectedIndustry.length = 6;
      }
    },

    retreiveStates(data) {
      this.fetchStates(data);
    },
    pluck(array, key) {
      return array.map((o) => o[key]);
    },
    formSubmitted() {
      this.show = true;
      const formatIndustries = [];
      const formatLanguage = [];
      const formatExperince = [];
      const formatLocation = [];
      if (this.createBrokerProfile.selectedIndustry) {
        this.createBrokerProfile.selectedIndustry.forEach((element) => {
          formatIndustries.push({
            _id: element,
          });
        });
      }
      this.items.forEach((element) => {
        formatLanguage.push({
          name: element.name.text,
          level: element.level,
        });
      });
      this.experiences.forEach((element) => {
        formatExperince.push({
          name: element.name,
          years: element.years,
          company: element.company,
          location: element.country.value,
        });
      });
      this.createBrokerProfile.selectedCountry.forEach((element) => {
        formatLocation.push(element.value);
      });
      const brokerData = new FormData();
      // eslint-disable-next-line no-underscore-dangle
      brokerData.append("userId", this.user._id);
      brokerData.append("userType", "broker");
      brokerData.append("fullName", this.createBrokerProfile.name);
      brokerData.append("emailAddress", this.createBrokerProfile.emailValue);
      brokerData.append("phoneNumber", this.phoneNumber.replace(/\s/g, ""));
      brokerData.append("phoneCode", this.phoneCode);
      brokerData.append("ownerType", this.createBrokerProfile.ownerType.value);
      if (this.createBrokerProfile.ownerType.value == "individual") {
        brokerData.append(
          "gender",
          this.createBrokerProfile.selectedGender.value
        );
      }

      brokerData.append(
        "nationality",
        this.createBrokerProfile.selectedNationality.value
      );
      brokerData.append(
        "licenseDetails",
        this.createBrokerProfile.licenseDetails
      );
      brokerData.append("previousWork", this.createBrokerProfile.previousWork);
      brokerData.append(
        "industries",
        JSON.stringify(this.createBrokerProfile.selectedIndustry)
      );
      brokerData.append("highlights", this.createBrokerProfile.Highlights);
      brokerData.append("description", this.createBrokerProfile.description);

      brokerData.append("address", JSON.stringify(formatLocation));

      brokerData.append("languages", JSON.stringify(formatLanguage));
      brokerData.append("experiences", JSON.stringify(formatExperince));
      if (this.createBrokerProfile.facebookUrl) {
        brokerData.append(
          "brokerFacebook",
          this.createBrokerProfile.facebookUrl
        );
      }
      if (this.createBrokerProfile.twitterUrl) {
        brokerData.append("brokerTwitter", this.createBrokerProfile.twitterUrl);
      }
      if (this.createBrokerProfile.linkedinUrl) {
        brokerData.append(
          "brokerLinkedIn",
          this.createBrokerProfile.linkedinUrl
        );
      }

      if (this.createBrokerProfile.OtherUrl) {
        brokerData.append(
          "brokerOtherLinks",
          this.createBrokerProfile.OtherUrl
        );
      }

      /* ----------- Tab5  ----------*/
      brokerData.append(
        "personalPhoto",
        this.createBrokerProfile.PersonalPhoto
      );
      brokerData.append("brokerProves", this.createBrokerProfile.BrokerProve);

      if (this.createBrokerProfile.BrokerCertificates) {
        for (const i of Object.keys(
          this.createBrokerProfile.BrokerCertificates
        )) {
          brokerData.append(
            "certificates",
            this.createBrokerProfile.BrokerCertificates[i]
          );
        }
      }

      if (this.createBrokerProfile.BrokerRelevant) {
        for (const i of Object.keys(this.createBrokerProfile.BrokerRelevant)) {
          brokerData.append(
            "otherDocuments",
            this.createBrokerProfile.BrokerRelevant[i]
          );
        }
      }

      this.createBroker(brokerData)
        .then((response) => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: response.data.message,
              icon: "EditIcon",
              variant: "success",
            },
          });
          this.show = false;
          this.$router.replace("/pages/pricing", () => {
            this.$router.go(0);
          });
          // this.$router.push({ name: "pages-pricing" });
        })
        .catch((error) => {
          this.show = false;
          this.errorMessage = error.response.data.error.message;
        });
    },
    removeThisFile(thisFile) {
      this.$refs.MyDropzone.removeFile(thisFile);
      console.log("File removed!");
    },
    validationForm() {
      return new Promise((resolve, reject) => {
        this.$refs.accountRules.validate().then((success) => {
          if (success && this.isValidNumber) {
            resolve(true);
            window.scrollTo(0, 0);
          } else {
            reject();
          }
        });
      });
    },
    validationFormInfo() {
      return new Promise((resolve, reject) => {
        this.$refs.infoRules.validate().then((success) => {
          if (success) {
            resolve(true);
            window.scrollTo(0, 0);
          } else {
            reject();
          }
        });
      });
    },
    validationFormAddress() {
      return new Promise((resolve, reject) => {
        this.$refs.addressRules.validate().then((success) => {
          if (success) {
            resolve(true);
            window.scrollTo(0, 0);
          } else {
            reject();
          }
        });
      });
    },
    validationFormSocial() {
      return new Promise((resolve, reject) => {
        this.$refs.socialRules.validate().then((success) => {
          if (success) {
            resolve(true);
            window.scrollTo(0, 0);
          } else {
            reject();
          }
        });
      });
    },
    validationFormFiles() {
      return new Promise((resolve, reject) => {
        this.$refs.attachmentRules.validate().then((success) => {
          if (success) {
            resolve(true);
            window.scrollTo(0, 0);
          } else {
            reject();
          }
        });
      });
    },
  },
  mounted() {
    this.fetchCategories();
    this.fetchIndustries();
    this.fetchCurrencies();
    this.fetchCountries();
    this.configValidate();
  },
};
</script>

<style type="text/css">
[dir] label {
  font-size: 14px;
}
[dir] .form-control {
  padding: 0.438rem 1rem;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #d8d6de;
  border-radius: 0.357rem;
  font-size: 15px;
}

[dir] .vue-form-wizard .wizard-card-footer .wizard-footer-right .wizard-btn {
  background-color: #366ce3;
  border-color: #2d5dc7;
}

[dir] .vs__selected {
  background-color: #366ce3;
}
</style>
